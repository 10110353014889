import { dom, library } from '@fortawesome/fontawesome-svg-core';
import { faX } from '@fortawesome/free-solid-svg-icons';
import { faCamera } from '@fortawesome/free-solid-svg-icons/faCamera';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons/faCircleNotch';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';

import { windowScrollToPolyfill } from 'seamless-scroll-polyfill';
import * as Sqrl from 'squirrelly';

import defaultHtml from './pages/default-template.html';
import './scss/styles.scss';
import { FeatureManagementService, MiSnapService, SessionService } from './services';
import { registerInterceptors } from './services/http-Interceptors';
import logRocketService from './services/log-rocket/log-rocket.service';
import msalService from './services/msal/msal-auth.service';
import { defaultClientTheme } from './services/session/models/client-theme.model';
import { registerComponents } from './shared/shared-components';
import state from './state';
import { registerRoutes } from './routes';
import { registerGadgetGuideResponse } from './shared/gadget-guide-response';
import { registerVideoCheckModal } from './shared/video-check-modal';

import crossroads = require('crossroads');
import hasher = require('hasher');
import { parseQueryParameters } from './shared/utils';
import { Feature } from './services/feature-management/models/feature.enum';

export async function main() {
  // Register specific icons we need
  library.add(faCamera, faCheck, faInfoCircle, faCircleNotch, faX);
  dom.watch();

  // Load smooth scroll polyfill (because Safari does not support smooth scrolling)
  windowScrollToPolyfill();

  // Load state from local storage
  state.loadState();

  // Register reusable templates
  registerComponents();

  // Render default html that uses templates
  const content = document.getElementById('content');
  if (content) content.innerHTML = Sqrl.render(defaultHtml, {});

  // Capture query parameters 
  captureQueryParameters();

  if (state.isVideoCheckModalV2Enabled === undefined) {
    state.setIsVideoCheckModalV2Enabled(await FeatureManagementService.isFeatureEnabled(Feature.VideoCheckModalV2));
  }

  // moved registerVideoCheckModal out of shared components (when ff is removed, can move these two calls into shared-components.ts)
  registerVideoCheckModal();
  registerGadgetGuideResponse();

  // Ensure user is authenticated before loading application.
  msalService.refreshToken().then(async (isValid) => {
    logRocketService.start();
    if (isValid) {
      await loadSessionDetails();
      hasher.init();
    }
  });

  // Route params always returned as an object
  crossroads.normalizeFn = crossroads.NORM_AS_OBJECT;

  registerRoutes();
  registerInterceptors();
}

async function loadSessionDetails(): Promise<void> {
  if (!state.firstName || !state.clientTheme) {
    const details = await SessionService.getSessionDetails();

    state.setFirstName(details.firstName ?? '');
    state.setClientTheme(details.clientTheme ?? defaultClientTheme);
  }

  SessionService.setFavicon(
    state.clientTheme,
    document.querySelector('link[rel="icon"]')
  );
  SessionService.setDocumentTitle(state.clientTheme, document);
  SessionService.injectCssVariablesInHead(state.clientTheme, document);

  await MiSnapService.getInstance().initialize();
}

function captureQueryParameters() {
  const queryParams = parseQueryParameters();
  
  if(Object.keys(queryParams).length != 0) {
    sessionStorage.setItem(
      'query-parameters',
      JSON.stringify(queryParams)
    );
  }
}

main();