import { RoutingService, SessionService } from '../../services';
import { DocumentType, FeedbackButtonIconType, FeedbackButtonType, Routes, Step } from '../../shared/types';
import state from '../../state';
import { Page } from '../page';
import { defaultClientTheme } from '../../services/session/models/client-theme.model';
import html from './landing-page.html';
import './landing-page.scss';
import { UAParser } from 'ua-parser-js';

export class LandingPage extends Page {
  public get name() {
    return 'Landing Page';
  }

  private readonly visibilityChangeHandle;
  private retryCameraCheckTimeout: NodeJS.Timeout;
  private hasSessionAlreadyStarted = false;
  public readonly isNewModal: boolean; 
  public isAmrockTheme: boolean;
  public temp = false;

  constructor() {
    super(html);
    this.visibilityChangeHandle = this.onVisibilityChange.bind(this);
    this.isNewModal = state.isVideoCheckModalV2Enabled ?? false;
  }

  getTemplateOptions(): Record<string, unknown> {
    return {
      firstName: state.firstName === '' ? 'Welcome' : state.firstName,
      browserType: new UAParser().getBrowser().name
    };
  }

  afterLoad(params: Record<string, unknown>): void {
    state.setVisitedSteps(params.request_ as string);
    this.setActiveStep(Step.ChooseDocument);

    // Only log session started on the first time this page is visited
    if (!this.hasSessionAlreadyStarted) {
      SessionService.postSessionStarted();
      this.hasSessionAlreadyStarted = true;
    }

    document.addEventListener('visibilitychange', this.visibilityChangeHandle);
    this.checkCameraPermissions();
    this.bindDocumentSelectionEvents();

    this.isAmrockTheme = state.clientTheme?.primaryColorHexCode === defaultClientTheme.primaryColorHexCode;
    if (this.isNewModal) {
      this.bindFeedbackSelectionEvents();
    }
  }

  onVisibilityChange() {
    if (!document.hidden) {
      this.checkCameraPermissions();
    }
  }

  checkCameraPermissions() {
    const modalClass = 'landing-page__video-check-modal';
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        // Must close the stream we opened so that MiSnap can capture video.
        stream.getTracks().forEach((track) => track.stop());
        document
          .querySelector('.' + modalClass)
          .classList.remove(modalClass + '--show');
      })
      .catch(() => {
        document
          .querySelector('.' + modalClass)
          ?.classList.add(modalClass + '--show');
        // Try again repeatedly for some devices that let you enable camera without leaving the browser
        this.retryCameraCheckTimeout = setTimeout(
          this.checkCameraPermissions.bind(this),
          2000
        );
      });
  }

  bindDocumentSelectionEvents() {
    const driversLicenseButton = document.getElementById(
      'drivers-license-option'
    );
    const stateIdButton = document.getElementById('state-id-option');

    driversLicenseButton?.addEventListener('click', () => {
      this.selectDocumentType(DocumentType.DriversLicense);
    });

    stateIdButton?.addEventListener('click', () => {
      this.selectDocumentType(DocumentType.StateId);
    });
  }

  selectDocumentType(docType: DocumentType) {
    state.setDocumentType(docType);
    RoutingService.goToPage(Routes.CaptureLicenseId);
  }

  bindFeedbackSelectionEvents() {
    const positiveButton = document.getElementById(FeedbackButtonType.Positive);
    const negativeButton = document.getElementById(FeedbackButtonType.Negative);
    const positiveIcon = document.getElementById(FeedbackButtonIconType.Positive);
    const negativeIcon = document.getElementById(FeedbackButtonIconType.Negative);
    const buttonClass = 'feedback-button';
    const unselected = '--unselected';
    const selected = '--selected';

    positiveButton?.addEventListener('click', () => {
      positiveButton.classList.remove(buttonClass + unselected);
      positiveButton.classList.add(buttonClass + selected);

      negativeButton.classList.remove(buttonClass + selected);
      negativeButton.classList.add(buttonClass + unselected);
      if (this.isAmrockTheme) {
        positiveIcon.classList.remove(buttonClass + '__icon--feedback-positive-unselected');
        positiveIcon.classList.add(buttonClass + '__icon--feedback-positive-selected');

        negativeIcon.classList.remove(buttonClass + '__icon--feedback-negative-selected');
        negativeIcon.classList.add(buttonClass + '__icon--feedback-negative-unselected');
      }
    });

    negativeButton?.addEventListener('click', () => { 
      negativeButton.classList.add(buttonClass + selected);
      negativeButton.classList.remove(buttonClass + unselected);

      positiveButton.classList.remove(buttonClass + selected);
      positiveButton.classList.add(buttonClass + unselected);

      if (this.isAmrockTheme) {
        negativeIcon.classList.add(buttonClass + '__icon--feedback-negative-selected');
        negativeIcon.classList.remove(buttonClass + '__icon--feedback-negative-unselected');
  
        positiveIcon.classList.remove(buttonClass + '__icon--feedback-positive-selected');
        positiveIcon.classList.add(buttonClass + '__icon--feedback-positive-unselected');
    }
    });
  }

  onLeavePage() {
    document.removeEventListener(
      'visibilitychange',
      this.visibilityChangeHandle
    );
    clearTimeout(this.retryCameraCheckTimeout);
  }
}
