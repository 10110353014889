import * as Sqrl from 'squirrelly';
import state from '../../state';

import template from './gadget-guide-response.html';

export function registerGadgetGuideResponse() {
    if (state.isVideoCheckModalV2Enabled) {
        Sqrl.templates.define('gadget-guide-response', Sqrl.compile(template, { useWith: true }));
    }
}
